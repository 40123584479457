import React, { useState, useEffect } from "react";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { FiSend } from "react-icons/fi";
import InputMask from "react-input-mask";
import "react-toastify/dist/ReactToastify.css";
import api from "../../api";

function LandingPageCampanha1() {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    nome: "",
    celular: "",
    challenger: 0
  });

  const [challenger, setChallenger] = useState({ value1: 0, value2: 0 });
  const antIcon = <LoadingOutlined style={{ fontSize: 52 }} spin />;

  useEffect(() => {
    const values = {};
    values.value1 = getRandom(1, 3);
    values.value2 = getRandom(3, 6);
    setChallenger(values);
  }, []);

  const onPress = (prop, value) => {
    form[prop] = value;
    setForm({ ...form });
  };

  const getRandom = (min, max) => {
    return (Math.random() * (max - min) + min).toFixed(0);
  };

  const validateForm = () => {
    if (!form.nome) {
      return {
        success: false,
        msg: "Por favor, informe seu nome"
      };
    }

    if (!form.celular) {
      return {
        success: false,
        msg: "Por favor, preencha o número de celular"
      };
    }

    const resultChallenger =
      parseInt(challenger.value1) + parseInt(challenger.value2);

    if (parseInt(resultChallenger) !== parseInt(form.challenger)) {
      return {
        success: false,
        msg: "Por favor, informe a soma corretamente."
      };
    }

    return {
      succes: true
    };
  };

  const sendForm = async vote => {
    console.log("enter send form");
    setLoading(true);
    try {
      const validate = validateForm();
      console.log("validate", validate);

      if (!validate.succes) {
        message.error(validate.msg);
        return false;
      }

      const data = {
        celular: form.celular,
        nome: form.nome
      };

      await api.post(`/services/campanhas/envio-notificacao`, data);

      setForm({
        nome: "",
        celular: "",
        challenger: 0
      });

      message.success(
        "Obrigado! Logo um especialista entrará em contato com você!"
      );

      setTimeout(() => {
        window.location.href = "https://br5saude.com.br";
      }, 5000);
    } catch (error) {
      message.error("Opss.. Houve um erro ao enviar suas informações.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className="home home-2 home-p home-blue-navi"
        style={{ position: "inherit" }}
      >
        <div className="container-xls">
          <div className="row">
            <div className=" col-md-12">
              <iframe
                style={{
                  width: "100%",
                  minHeight: "400px"
                }}
                src="https://www.youtube.com/embed/vkNbAkPX2Bc"
                title="Conheça os Benefícios da BR5 Saúde."
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="col-md-12 col-md-offset-1">
              <p
                className="text-bold mb-0 text-center"
                style={{ fontSize: 24 }}
              >
                NÃO PERCA ESTA OPORTUNIDADE!
              </p>
              <p className="mb-5">
                Proteja agora sua família com 5 Grandes benefícios em um único
                produto!
              </p>
            </div>
          </div>
          {loading && (
            <Spin
              indicator={antIcon}
              loading={loading}
              style={{
                margin: "0 auto",
                display: "block"
              }}
            />
          )}
          <form>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Seu nome*</label>
                <input
                  value={form.nome}
                  onChange={event => {
                    onPress("nome", event.target.value);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Nome"
                />
              </div>
              <div className="form-group col-md-12">
                <label>Celular (WhatsApp)*</label>
                <InputMask
                  mask="(99) 99999-9999"
                  className="form-control"
                  value={form.celular}
                  onChange={event => {
                    onPress("celular", event.target.value);
                  }}
                  type="text"
                  id="inp"
                  placeholder="&nbsp;"
                />
              </div>
              {/* <div className="form-group col-md-12">
                <label>E-mail</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="email"
                />
              </div> */}
            </div>
            <div className="form-row">
              <div className="form-group col-md-2">
                <label>
                  {challenger.value1} + {challenger.value2}
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Desafio"
                  value={form.challenger}
                  onChange={event => {
                    onPress("challenger", event.target.value);
                  }}
                />
              </div>
              <div className="form-group col-md-10">
                <button
                  disabled={loading}
                  type="button"
                  className="btn btn-danger btn-block"
                  style={{
                    marginTop: "30px"
                  }}
                  onClick={() => sendForm()}
                >
                  <FiSend className="mr-2" style={{ marginBottom: "-3px" }} />
                  QUERO SABER MAIS SOBRE A BR5 SAÚDE
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default LandingPageCampanha1;
