import React from "react";

function Download() {
  return (
    <>
      <div className="home home-2 home-p" style={{ position: "inherit" }}>
        <div className="container-m">
          <div className="row">
            <div className="col-md-12 col-md-offset-1">
              <div className="hero-content wow fadeIn">
                <div className="hero-content-inner mb-1">
                  <h1>Baixe nosso aplicativo!</h1>
                  <p>
                    Tenha acesso ao seu plano, baixe nosso aplicativo através
                    dos links abaixo e assine digitalmente.
                  </p>

                  <div
                    className="mb-3 mt-3 d-block"
                    style={{ width: "100%", display: "inline-block" }}
                  >
                    <a
                      className="btn-download-app js-scroll-trigger"
                      href="https://play.google.com/store/apps/details?id=sou.br5"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        src="./img/download-google.png"
                        alt=""
                        style={{ width: "125px" }}
                      />
                    </a>
                    <a
                      className="btn-download-app js-scroll-trigger"
                      href="https://apps.apple.com/br/app/br5-benef%C3%ADcios/id1496268364"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        src="./img/download-ios.png"
                        alt=""
                        style={{ width: "125px" }}
                      />
                    </a>
                  </div>

                  <p className="">
                    <b>Depois de baixar é só seguir as instruções abaixo:</b>{" "}
                    <br />
                    <br />
                    1. Primeiro acesso <br />
                    2. Clique no seu numero de fone para receber o codigo
                    <br />
                    3. Crie uma senha com números e letras.
                    <br />
                    4. Assine digitalmente seu contrato e pronto.
                    <br />
                    <br />
                    Comece a usar os benefícios do seu plano agora !<br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Download;
