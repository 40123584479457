import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";

function App() {
  const location = window.location.pathname;

  if (location.includes("fatura")) {
    return (
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <div className="wrapper">
        <nav className="navbar navbar-expand-md navbar-light navbar-alt bg-light fixed-top no-border">
          <div className="container">
            <a href="#" className="brand-default">
              <img src="/vertical-logo-colorido.png" alt="photo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto navbar-right">
                <li className="nav-item">
                  <a
                    className="nav-link js-scroll-trigger"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://br5beneficios.com.br"
                  >
                    Visite nosso site
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div id="main" className="main">
          <Routes />

          <div className="footer">
            <div className="container">
              <p
                className="text-center"
                style={{ color: "#fff", fontSize: "16px" }}
              >
                Atendimento de segunda a sexta das 08:00 às 18:00
              </p>
              <div className="row text-center">
                <div className="col-lg-2 col-md-3 col-sm-12">
                  <div className="footer-logo">
                    <h2>BR5 Benefícios</h2>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <ul className="footer-menu">
                    <li>
                      <a href="https://br5beneficios.com.br">
                        Site BR5 Benefícios
                      </a>{" "}
                    </li>
                    <li>
                      <a href="https://app.economybrasil.com.br.com.br">
                        Sistema
                      </a>{" "}
                    </li>
                    <li>
                      <a href="https://br5beneficios.com.br">Fale Conosco</a>{" "}
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 col-md-3 col-sm-12">
                  <div className="footer-links">
                    <ul>
                      <li>
                        <a href="#main">
                          {" "}
                          <img
                            className="img-fluid"
                            src="/icons/in.png"
                            alt="Icon"
                          />
                        </a>{" "}
                      </li>
                      <li>
                        <a href="#main">
                          {" "}
                          <img
                            className="img-fluid"
                            src="/icons/li.png"
                            alt="Icon"
                          />
                        </a>{" "}
                      </li>
                      <li>
                        <a href="#main">
                          {" "}
                          <img
                            className="img-fluid"
                            src="/icons/tw.png"
                            alt="Icon"
                          />
                        </a>{" "}
                      </li>
                      <li>
                        <a href="#main">
                          {" "}
                          <img
                            className="img-fluid"
                            src="/icons/tt.png"
                            alt="Icon"
                          />
                        </a>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <a
                id="back-top"
                className="back-to-top js-scroll-trigger"
                href="#main"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
