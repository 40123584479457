import React from "react";
import { Switch, Route } from "react-router-dom";

import Main from "../components/Main";
import Download from "../components/Download";
import PosVenda from "../components/PosVenda";
import Encurtador from "../components/Encurtador";
import PagamentoBoleto from "../components/PagamentoBoleto";
import Carne from "../components/Carne";
import Telemedicina from "../components/Telemedicina";
import TelemedicinaThanks from "../components/Telemedicina/Thanks";
import { LandingPageWhatsapp } from "../components/LandingPage";

const routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={Main}></Route>
      <Route path="/ads" component={LandingPageWhatsapp}></Route>
      <Route path="/aceite" component={Download}></Route>
      <Route path="/posvenda/:id" component={PosVenda}></Route>
      <Route path="/encurtador" component={Encurtador}></Route>
      <Route path="/fatura/:id" component={PagamentoBoleto}></Route>
      <Route path="/carne/:id" component={Carne}></Route>
      <Route
        path="/telemedicina/obrigado"
        exact
        component={TelemedicinaThanks}
      ></Route>
      <Route path="/telemedicina/:id" exact component={Telemedicina}></Route>
      {/* <Route path="/carne/:doc" exact component={PagamentoBoleto}></Route> */}
    </Switch>
  );
};

export default routes;
