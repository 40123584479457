import React, { useState } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { message } from "antd";
import { AiOutlineCopy } from "react-icons/ai";

function Encurtador() {
  const [form, setForm] = useState({});
  const [shortUrl, setShortUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const onPress = (prop, value) => {
    form[prop] = value;
    setForm({ ...form });
  };

  const submit = async () => {
    console.log("111");
    const key = form?.urlLonga;
    message.loading({
      content: "Um momento, estamos efetuando o pagamento da sua fatura :)",
      key
    });
    setLoading(true);

    if (!form?.urlLonga) {
      setLoading(false);
      return message.warning({
        content:
          "Opss... Para encurtar seu link é necessário preencher o campo!",
        key,
        duration: 10
      });
    }

    const data = {
      longUrl: form?.urlLonga
    };

    try {
      const { data: collection } = await axios.post("https://br5sau.de/", data);
      const { data: _data } = collection;
      setShortUrl(_data.shortUrl);

      message.success({
        content: "Seu link foi encurtado :)",
        key,
        duration: 10
      });
    } catch (error) {
      message.error({
        content: "Opss... Não foi possível encurtar seu link!",
        key,
        duration: 10
      });
    }
    setLoading(false);
  };

  const copyToClipboard = value => {
    var textArea = document.createElement("textarea");
    textArea.value = value;
    document.body.appendChild(textArea);
    textArea.select();
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    if (msg === "successful") {
      message.success("Copiado para a área de transferência!", 5);
    }
    document.body.removeChild(textArea);
  };

  return (
    <>
      <ToastContainer autoClose={5000} />
      <div className="home home-2 home-p" style={{ position: "inherit" }}>
        <div className="container-xs">
          <div className="row">
            <div className="col-md-12 col-md-offset-1">
              <div>
                <input
                  type="text"
                  className="form-control"
                  id="url-longa"
                  aria-describedby="url-longa"
                  placeholder="URL Longa"
                  onChange={event => onPress("urlLonga", event.target.value)}
                />

                {/* <div>
                  <label>br5sau.de/</label>
                  <input
                    type="text"
                    className="form-control"
                    id="identificador"
                    aria-describedby="identificador"
                    placeholder="Identificador"
                  />
                </div> */}

                {shortUrl && (
                  <div>
                    <div className="row mt-3">
                      <div className="col-12 text-center">
                        <label style={{ fontSize: `${15}px` }}>
                          Seu link encurtado é:
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        <label
                          style={{ fontSize: `${15}px`, fontWeight: "bold" }}
                        >
                          {shortUrl || ""}
                        </label>

                        <AiOutlineCopy
                          style={{
                            marginLeft: 10,
                            width: `${20}px`,
                            height: `${20}px`,
                            cursor: "pointer"
                          }}
                          onClick={() => copyToClipboard(shortUrl)}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <button
                  disabled={loading}
                  style={{ marginTop: 20 }}
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={() => submit()}
                >
                  ENCURTAR!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Encurtador;
