import axios from "axios";
import { message } from "antd";
import { base } from "../config";

function response() {
  const instance = axios.create({ baseURL: base });
  instance.interceptors.response.use(
    response => {
      const { data } = response;
      // const { method, url } = config;

      // switch(method) {
      //   case 'post':
      //     // notify success here
      //   break;
      //   default:
      //     // nothing here
      //   break;
      // }

      return data;
    },
    error => {
      const { response = {} } = error;
      const { data, status } = response;

      if (status === 401) {
        // error auth
      }

      if (data && data.error && data.error.length) {
        message.error(data.error, 10);
      }
      return Promise.reject(error);
    }
  );

  return instance;
}

export default response;
