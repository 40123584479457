const production = true;
let base;

if (production) {
  base = "https://api.br5beneficios.com.br";
  // base = "http://192.168.1.94:3000";
  // base = "http://192.168.1.93:1002";
} else {
  base = "http://localhost:3000";
}

export { base, production };
