import React, { useState, useEffect } from "react";
import api from "../../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PosVenda({ match = {} }) {
  const { params } = match;
  const [personID, setPersonID] = useState("");
  const [isVoted, setIsVoted] = useState(false);

  useEffect(() => {
    setIsVoted(false);
  }, []);

  useEffect(() => {
    setPersonID(params?.id);
  }, [params]);

  const sendVote = async vote => {
    if (!personID) return;
    const data = {
      feedback: vote
    };

    await api.post(`/externo/pos-venda/${personID}`, data).then(() => {
      setIsVoted(true);
      toast.success("Avaliação feita com sucesso, obrigado!", {
        closeOnClick: true
      });
    });
  };

  return (
    <>
      <ToastContainer autoClose={5000} />
      <div className="home home-2 home-p" style={{ position: "inherit" }}>
        <div className="container-xs">
          <div className="row">
            <div className="col-md-12 col-md-offset-1">
              <div className="hero-content wow fadeIn">
                <div className="hero-content-inner mb-1">
                  <div className="container-satisfaction mb-5">
                    <div>
                      <img
                        alt="img-1"
                        className="title-image"
                        src={
                          process.env.PUBLIC_URL +
                          "/img/rectangle-satisfaction.svg"
                        }
                      />

                      <span
                        className="title-satisfaction"
                        style={{ position: "absolute", top: "8%" }}
                      >
                        Pesquisa de satisfação
                      </span>
                    </div>
                    <div className="body-welcome">
                      <span className="title-welcome">Seja bem vindo!</span>{" "}
                      <br />
                      <div className="center-welcome">
                        <p className="description-welcome-2">
                          BR5 Benefícios - melhor Aplicativo de descontos em
                          Saúde do Brasil
                        </p>
                        <p className="description-welcome-2">
                          Agora você tem direito a consultas à partir de R$20,00
                          ou com até 60% de desconto (via Central); descontos em
                          dentistas com até 40% de economia, descontos em
                          farmácias na rede Drogasil, seguro de vida e mais
                          assistência funeral nacional familiar.
                        </p>
                        <p className="description-welcome-2">
                          Para utilizar nossos serviços, baixe agora nosso
                          aplicativo no link xxxx e já solicite consultas
                          médicas, exames para você e sua família no mesmo
                          instante.{" "}
                        </p>
                        <p className="description-welcome-2">
                          Gostaríamos de saber como foi sua primeira experiência
                          com nossa empresa:{" "}
                        </p>
                      </div>
                      <span className="footer-welcome">
                        Queremos saber como foi sua experiência com nossa
                        prestação de serviços.
                      </span>
                      {!isVoted && (
                        <div className="row mt-5">
                          <div className="col">
                            <span className="title-search">Ótimo</span>
                            <br />
                            <img
                              alt="img-2"
                              onClick={() => sendVote("otimo")}
                              className="image-search"
                              src={process.env.PUBLIC_URL + "/img/_otimo.svg"}
                            />
                          </div>
                          <div className="col">
                            <span className="title-search">Bom</span>
                            <br />
                            <img
                              alt="img-3"
                              onClick={() => sendVote("bom")}
                              className="image-search"
                              src={process.env.PUBLIC_URL + "/img/_bom.svg"}
                            />
                          </div>
                          <div className="col">
                            <span className="title-search">Regular</span>
                            <br />
                            <img
                              alt="img-4"
                              onClick={() => sendVote("regular")}
                              className="image-search"
                              src={process.env.PUBLIC_URL + "/img/regular.svg"}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PosVenda;
