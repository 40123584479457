import React, { useEffect, useState } from "react";
import { FiUser, FiStar, FiCalendar } from "react-icons/fi";
import { BiDislike, BiLike } from "react-icons/bi";
import {
  formatNumber,
  formatDate,
  getMonth,
  getYear,
  getMonthYear
} from "../../utils/helpers";
import { Spin, Space } from "antd";
import api from "../../api";

import { useHistory } from "react-router-dom";

/**
 * Anotações
 * --------------------------------------------------------------------------
 * - Problema: linha 23 - 'match' is missing in props validationeslintreact/prop-types
 * - Correção: Descomentar linhas 187 a 189
 * --------------------------------------------------------------------------
 * --------------------------------------------------------------------------
 * - Problema: linha 49 - Você não garente que contas é um array, se contas
 * não for um array provavelmente irá lançar uma exceção no bloco catch
 * - Correção: Tratar o erro com throw para que não lance um typeError
 * para o cliente
 * --------------------------------------------------------------------------
 * --------------------------------------------------------------------------
 * - Problema: Você não garente que cliente é um objeto, se cliente
 * não for um objeto provavelmente irá lançar uma exceção no bloco catch
 * - Correção: Tratar o erro com throw para que não lance um typeError
 * para o cliente
 * --------------------------------------------------------------------------
 * --------------------------------------------------------------------------
 * - Problema: Linha 68 - Você deve deixar os erros que são da resposta
 * xmlhttprequest e erros lançados através de exceptions visíveis ao cliente
 * - Correção: Usar um componente de alerta ao invés de console.log
 * para dar o feedback do erro
 * --------------------------------------------------------------------------
 */

const Carne = ({ match }) => {
  const history = useHistory();
  const { params } = match;

  const [contas, setContas] = useState([]);
  const [cliente, setCliente] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    populate();
  }, []);

  const populate = async () => {
    try {
      setLoading(true);
      const { cliente, contas } = await api.get(`services/carne/${params?.id}`);

      setContas([...contas]);
      setCliente({ ...cliente });
    } catch (error) {
      console.log(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  const handleDetails = id => {
    history.push(`/fatura/${id}`);
  };

  return (
    <>
      <div className="home home-3 home-p" style={{ position: "inherit" }}>
        <div className="container-m">
          <div className="row">
            <div className="col-md-12 col-md-offset-1">
              <div
                className="hero-content wow fadeIn"
                style={{ display: "block" }}
              >
                <div className="hero-content-inner mb-1 text-center">
                  <h2 className="text-white ">
                    <b>Carnê Digital</b>
                  </h2>

                  <hr className="divider-white" />

                  {loading && <Spin size="large" />}
                  {!loading && !contas?.length && (
                    <h2 className="text-white ">
                      <b>Nenhum carnê encontrado :(</b>
                    </h2>
                  )}
                  {!loading && contas.length > 0 && (
                    <>
                      <div>
                        <span className="border-right-width pr-4 text-name-plan">
                          <FiUser /> {cliente?.nome}
                        </span>
                        <span className="pl-4 text-name-plan">
                          <FiStar /> {cliente?.tipoPlano}
                        </span>
                      </div>

                      <div>
                        <span className="text-since">
                          <FiCalendar /> Cliente desde{" "}
                          <strong>{getMonthYear(cliente?.dataCadastro)}</strong>
                        </span>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          {contas.length &&
                            contas.map((item, key) => (
                              <div
                                onClick={() => handleDetails(item?._id)}
                                key={key}
                                className="item"
                              >
                                <div className="box box-shadow"></div>
                                <div className="box box-item p-4">
                                  <div className="row">
                                    <div className="col-9">
                                      <div style={{ display: "flex" }}>
                                        <div className="circle-number">
                                          <span className="text-circle">
                                            <b>{key + 1}</b>
                                          </span>
                                        </div>
                                        <h3
                                          className="ml-3 text-white mb-0"
                                          style={{
                                            alignSelf: "center"
                                          }}
                                        >
                                          <b>
                                            Parcela de{" "}
                                            {getMonth(item?.dataVencimento)}/
                                            {getYear(item?.dataVencimento)}
                                          </b>
                                        </h3>
                                      </div>

                                      <div className="mt-4">
                                        <h3 className="text-white text-left">
                                          <b>Valor:</b>{" "}
                                          {formatNumber(item?.total)}
                                        </h3>
                                        <h3 className="mt-3 text-white text-left">
                                          <b>Vencimento:</b>{" "}
                                          {formatDate(item?.dataVencimento)}
                                        </h3>
                                      </div>
                                    </div>
                                    <div className="col-3">
                                      <div style={{ float: "right" }}>
                                        <h3 className="text-white mb-0 mt-2">
                                          {item?.dataConciliacao ? (
                                            <BiLike fontSize={24} />
                                          ) : (
                                            <BiDislike fontSize={24} />
                                          )}
                                          <b>
                                            {item?.dataConciliacao
                                              ? "Pago"
                                              : "Não Pago"}
                                          </b>
                                        </h3>
                                        <button
                                          style={{ marginTop: 20 }}
                                          type="button"
                                          className="btn btn-primary mt-5 button-carne"
                                          onClick={() =>
                                            handleDetails(item?._id)
                                          }
                                        >
                                          VER DETALHES
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          <div className="item">
                            <div className="box-shadow"></div>
                            <div className="box-item"></div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Carne.propTypes = {
//   match: PropTypes.object.isRequired
// };

export default Carne;
