import axiosInstance from "axios";
import moment from "moment";

moment().locale("pt");

moment.updateLocale("pt", {
  months: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ]
});

function clone(obj) {
  if (null == obj || "object" != typeof obj) return obj;
  var copy = obj.constructor();
  for (var attr in obj) {
    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  }
  return copy;
}

const isCelular = cel => {
  if (!cel) {
    return null;
  }
  let ncel = cel.toString();
  ncel = ncel.replace(/\D/g, "");
  const r = ncel.match(
    /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[6-9])\d{3})-?(\d{4}))$/
  );
  if (!r || !r.length || r.length < 1) {
    return false;
  }
  return true;
};

const formatNumber = (number = 0) => {
  return `R$ ${number.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })}`;
};

const formatDate = date => {
  if (!date) return "-";
  return moment(date)
    .utcOffset(0)
    .format("DD/MM/YYYY");
};

const getYear = date => {
  if (!date) return "-";
  return moment(date)
    .utcOffset(0)
    .format("YYYY");
};

const getMonth = date => {
  if (!date) return "-";
  return moment(date)
    .locale("pt-br")
    .utcOffset(0)
    .format("MMMM");
};

const getMonthYear = date => {
  if (!date) return "-";
  return moment(date)
    .locale("pt-br")
    .utcOffset(0)
    .format("MM/YYYY");
};

const getIpUsuario = async () => {
  let dataIp = await axiosInstance.get("https://api.ipify.org?format=json");
  let ip = dataIp.data.ip;
  if (!dataIp) {
    dataIp = await axiosInstance.get("http://ipinfo.io/ip");
    ip = dataIp;
  }
  if (!dataIp) {
    dataIp = await axiosInstance.get("http://ip-api.com/json/");
    ip = dataIp.query;
  }
  if (!dataIp) {
    dataIp = await axiosInstance.get("https://api.myip.com");
    ip = dataIp.ip;
  }
  return ip;
};

export {
  clone,
  isCelular,
  formatNumber,
  getIpUsuario,
  formatDate,
  getYear,
  getMonth,
  getMonthYear
};
