import React from "react";
import { Tooltip, Tag } from "antd";

import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

function Main() {
  return (
    <>
      <div className="home home-2">
        <div className="container-m">
          <div className="row">
            <div className="col-md-6 col-md-offset-1">
              <div className="hero-content wow fadeIn">
                <div className="hero-content-inner">
                  <h1>Central de Atendimento</h1>
                  <p>
                    Seja bem vindo a nossa centrao de atendimento BR5
                    Benefícios.
                  </p>
                  <p>
                    Aqui você pode consultar seus boletos, reativar seu plano,
                    indicar amigos e adiquirir seu plano
                  </p>
                  <a
                    className="btn-action btn-white js-scroll-trigger"
                    href="#services"
                  >
                    Serviços
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-5 text-center">
              <img
                className="img-logo-1"
                src="./logo-br5-beneficios.png"
                alt=""
              />
            </div>
            {/* <div className="col-md-6">
        <div className="hero-form">
          <form id="contactForm" data-toggle="validator" className="shake">
           <div className="row">
               <div className="form-group col-sm-12">
                   <label for="name" className="h4">Company*</label>
                   <input type="text" className="form-control" id="name" placeholder="Company Name" required data-error="Name is required" />
                   <div className="help-block with-errors"></div>
               </div>
               <div className="form-group col-sm-12">
                   <label for="email" className="h4">Email*</label>
                   <input type="email" className="form-control" id="email" placeholder="Email address" required />
                   <div className="help-block with-errors"></div>
               </div>
             </div>
             <button type="submit" id="form-submit" className="btn-action">Get Demo</button>
             <div id="msgSubmit" className="h3 text-center hidden"></div>
             <div className="clearfix"></div>
           </form>
        </div>
      </div> */}
          </div>
        </div>
      </div>

      <div id="services" className="lbl-services">
        <div className="container">
          {/* <div className="row justify-center">
      <div className="col-md-8">
        <div className="service-intro wow fadeInDown text-center">
         <h1>Using Ura to build your site is easy</h1>
         <p>
           Best in class big data software and analytics services will render your huge chunks into meaningful
          data. Try the demo now.
        </p>
        </div>
      </div>
    </div> */}
          <div className="lbl_cards">
            <a className="card_single card2" href="!#">
              <div className="card-icon">
                <div className="card-img">
                  <Tooltip title="Em Breve">
                    <img src="/icons/002.png" width="50" alt="Feature" />
                  </Tooltip>
                </div>
              </div>
              <div className="card-text">
                <h3>Segunda via de Boletos</h3>
                <Tag color="#3BABB7">Em breve</Tag>
                <p>Retire facilmente segunda via de boletos online.</p>
              </div>
            </a>
            <a className="card_single card3" href="!#">
              <div className="card-icon">
                <div className="card-img">
                  <Tooltip title="Em Breve">
                    <img src="/icons/003.png" width="50" alt="Feature" />
                  </Tooltip>
                </div>
              </div>
              <div className="card-text">
                <h3>Indique e ganhe!</h3>
                <Tag color="#3BABB7">Em breve</Tag>
                <p>
                  Ao indicar um amigo você ganha vantagens exclusivas.{" "}
                  <b>Clique e descubra</b>.
                </p>
              </div>
            </a>
            <a className="card_single card1" href="!#">
              <div className="card-icon">
                <div className="card-img">
                  <Tooltip title="Em Breve">
                    <img src="/icons/004.png" width="50" alt="Feature" />
                  </Tooltip>
                </div>
              </div>
              <div className="card-text">
                <h3>Reative seu plano</h3>
                <Tag color="#3BABB7">Em breve</Tag>
                <p>
                  Reative seu plano agora e GANHE 100% DE DESCONTO NA ADESÃO.
                </p>
              </div>
            </a>
            <a className="card_single card3" href="!#">
              <div className="card-icon">
                <div className="card-img">
                  <Tooltip title="Em Breve">
                    <img src="/icons/005.png" width="50" alt="Feature" />
                  </Tooltip>
                </div>
              </div>
              <div className="card-text">
                <h3>Atendimento Online</h3>
                <Tag color="#3BABB7">Em breve</Tag>
                <p>
                  Fale diretamente com um consultor de forma fácil e rápida.
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;
