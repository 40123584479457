/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { message, Tag, Modal } from "antd";
import "./css.css";
import "./css.sass";
import {
  FiLock,
  FiShield,
  FiAward,
  FiDownload,
  FiCreditCard,
  FiCheck
} from "react-icons/fi";

import api from "../../api";
import moment from "moment";
import { formatNumber, getIpUsuario } from "../../utils/helpers";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import { base } from "../../config";

function PagamentoBoleto({ match }) {
  const { params } = match;

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});
  const [fatura, setFatura] = useState({});
  const [modalPix, setModalPix] = useState(false);

  const onPress = (prop, value) => {
    form[prop] = value;
    setForm({ ...form });
  };

  const getInfosBillet = async () => {
    try {
      const { data } = await axios.get(
        `${base}/services/fatura/download/${params?.id}`
      );
      console.log("data", data);
      return data.data;
    } catch (error) {
      console.log("error", error);
      message.error(error.message || "Erro ao buscar fatura", 10);
    }
  };

  const downloadBillet = async () => {
    verifyLead(null, false, true);
    const info = await getInfosBillet();
    console.log("info", info);
    const url = info?.url || info?.urlFatura;
    let win = window.open(url, "_blank");
    win.focus();
  };

  const goToSection = section => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  const formatDate = date => {
    if (!date) return "-";
    return moment(date)
      .utcOffset(0)
      .format("DD/MM/YYYY");
  };

  useEffect(() => {
    getFatura();
  }, []);

  const getFatura = async () => {
    const { data: collection } = await api.get(
      `services/fatura/info/${params?.id}`
    );
    setFatura(collection);
    verifyLead(collection);
  };

  const efetuarPagamento = async () => {
    const key = fatura?.id;
    message.loading({
      content: "Um momento, estamos efetuando o pagamento da sua fatura :)",
      key
    });
    setLoading(true);
    if (
      !(
        form?.cardName ||
        form?.cardNumber ||
        form?.monthCard ||
        form?.yearCard ||
        form?.cvv
      )
    ) {
      setLoading(false);
      return message.warning({
        content:
          "Opss... Para efetuar o pagamento é necessário preencher todas as informações!",
        key,
        duration: 10
      });
    }
    const data = {
      creditCard: {
        holderName: form?.cardName,
        number: form?.cardNumber,
        expiryMonth: form?.monthCard,
        expiryYear: form?.yearCard,
        cvv: form?.cvv
      },
      ip: await getIpUsuario()
    };

    try {
      await api.post(`services/fatura/credito/${key}`, data);
      message.success({
        content: "Pagamento realizado com sucesso :)",
        key,
        duration: 10
      });
      verifyLead(null, true);
      getFatura();
    } catch ({ response }) {
      message.error({
        content: "Opss... Não foi possível completar o pagamento!",
        key,
        duration: 10
      });
    }

    setLoading(false);
  };

  const verifyLead = async (
    collection = null,
    buyer = false,
    downloadBillet = false
  ) => {
    if (fatura?.dataConciliacao || collection?.dataConciliacao) buyer = true;
    const data = {
      name: collection?.cliente?.nome || fatura?.cliente?.nome,
      dueDate: collection?.dataVencimento || fatura?.dataVencimento,
      invoiceId: collection?.id || fatura?.id,
      buyer,
      downloadBillet
    };
    axios.post(
      "https://checkoutapi.br5beneficios.com.br/api/v1/lead",
      // "http://192.168.1.93:1002/api/v1/lead",
      data
    );
  };

  const getStatusTag = () => {
    switch (fatura?.status) {
      case "Pago":
        return "green";

      case "Pendente":
        return "red";

      case "Disponível":
        return "geekblue";

      default:
        return "green";
    }
  };

  const openModalPix = () => {
    navigator.clipboard.writeText(fatura.pix.payload);
    setModalPix(true);
  };

  return (
    <>
      <div className="wrapper-billet" style={{ marginTop: 10 }}>
        <div>
          <div className="container">
            <div className="card-billet text-center">
              <div className="rounded-logo">
                <img
                  width="100"
                  height="95"
                  src={process.env.PUBLIC_URL + "/img/logo-br5-white.png"}
                  alt="logo-br5"
                  style={{ padding: `${16}px` }}
                />
              </div>
              <div className="pr-3 pr-3">
                <label className="text-bold mb-0" style={{ fontSize: 18 }}>
                  Fatura da BR5 Benefícios
                </label>{" "}
                <br />
                <label className="mb-0">
                  Boleto do(a) {fatura?.cliente?.nome}
                </label>{" "}
                <br />
                <label className="mb-0">
                  Fatura <b>#{fatura?.id}</b>
                </label>
                <br />
                <label
                  className="mb-0"
                  bold
                  style={{
                    fontSize: 20
                  }}
                >
                  <Tag color={getStatusTag()}>{fatura?.status}</Tag>
                </label>{" "}
                <br />
                <label className="mb-0">
                  Endereço{" "}
                  <b>{`${fatura?.cliente?.enderecos[0]?.rua}, ${fatura?.cliente?.enderecos[0]?.cidadeNome}, ${fatura?.cliente?.enderecos[0]?.bairro}`}</b>
                </label>
                <label className="label-info">
                  A COBERTURA DO SEGURO E FUNERAL SOMENTE SERÃO REATIVADOS E
                  TERÃO COBERTURA 60 (SESSENTA DIAS) APÓS QUITAÇÃO DAS
                  MENSALIDADES ATRASADAS
                </label>
              </div>
              <hr />
              {!fatura?.dataConciliacao && (
                <div className="row pr-5 pl-5">
                  <div className="col-sm-12 col-md-4 mb-sm-3">
                    <button
                      onClick={openModalPix}
                      type="button"
                      style={{
                        minWidth: `${100}%`,
                        fontSize: 16,
                        borderColor: "#28a745",
                        backgroundColor: "#28a745"
                      }}
                      className="text-button btn-block btn-success btn btn-outline-success btn-lg"
                    >
                      <span className="icon-pix mr-2" /> Pagar agora com PIX
                    </button>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <button
                      onClick={() => goToSection("credit-card-section")}
                      type="button"
                      style={{
                        minWidth: `${100}%`,
                        fontSize: 16
                      }}
                      className="text-button btn-block btn btn-outline-primary btn-lg"
                    >
                      <FiCreditCard size={20} className="mr-2" /> Pagar com
                      Cartão de Crédito
                    </button>
                  </div>
                  <div className="button-billet col-sm-12 col-md-4">
                    <button
                      onClick={() => downloadBillet()}
                      type="button"
                      style={{
                        minWidth: `${100}%`,
                        fontSize: 16
                      }}
                      className="text-button btn-block btn btn-outline-primary btn-lg"
                    >
                      <FiDownload size={20} className="mr-2 mb-1" /> Baixar
                      Boleto
                    </button>
                  </div>
                </div>
              )}

              <div className="row mt-4 pr-5 pl-5">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="mt-0">
                    <small style={{ fontSize: `${8}px` }}>
                      IDENTIFICAÇÃO DA FATURA {fatura?.id}
                    </small>
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "right" }}>
                  <label style={{ fontSize: `${8}px` }}>
                    <small>DATA DE EMISSÃO: 06/10/2020</small>
                  </label>
                </div>
                <div className="col-6 mt-3 mb-3" style={{ textAlign: "left" }}>
                  <div>
                    <h1 className="display-15 mb-0">Dados da Fatura</h1>
                    <p className="mt-0">
                      <small>Abaixo, informações da respectiva fatura</small>
                    </p>
                  </div>
                </div>
                <div className="col-6 mt-3 mb-3" style={{ textAlign: "right" }}>
                  <div>
                    <h1 className="display-15 mb-0">Vencimento</h1>
                    <p className="mt-0">{formatDate(fatura?.dataVencimento)}</p>
                  </div>
                </div>
                <div className="col-12">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" style={{ textAlign: "left" }}>
                          <b>Descrição</b>
                        </th>
                        <th scope="col"></th>
                        <th scope="col" style={{ textAlign: "right" }}>
                          <b>Valor</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "left" }}>
                          {fatura?.descricao}
                        </td>
                        <td></td>
                        <td style={{ textAlign: "right" }}>
                          {formatNumber(fatura?.valor)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left" }}></td>
                        <td>Desconto</td>
                        <td style={{ textAlign: "right" }}>
                          R$ {formatNumber(fatura?.desconto)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left" }}></td>
                        <td>Juros</td>
                        <td style={{ textAlign: "right" }}>
                          R$ {formatNumber(fatura?.juros)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left" }}></td>
                        <td>Subtotal</td>
                        <td style={{ textAlign: "right" }}>
                          {formatNumber(fatura?.valor)}
                        </td>
                      </tr>
                      <tr id="credit-card-section">
                        <td style={{ textAlign: "left" }}></td>
                        <td className="text-bold">Total</td>
                        <td
                          className="text-bold"
                          style={{ textAlign: "right" }}
                        >
                          {formatNumber(fatura?.total)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <hr />
              {fatura?.dataConciliacao && (
                <section className="about-us mt-5 pr-4 pl-4" id="about-us">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <h1 style={{ color: "#28a745" }}>Conta já paga!</h1>
                        <h2>Olá, {fatura?.cliente?.nome} </h2>
                        <hr />
                        <p>
                          A fatura em questão encontra-se conciliada e foi paga
                          na data de{" "}
                          <b>{formatDate(fatura?.dataConciliacao)}</b>, abaixo
                          você pode visualiza-la clicando no botão{" "}
                          <b>"Comprovante".</b>
                        </p>
                        <p>
                          Obrigado pela preferência, caso reste alguma dúvida,
                          você pode tira-las na nossa central.
                        </p>
                        {/* <button
                          onClick={() => downloadBillet()}
                          type="button"
                          className="btn btn-success mb-5"
                        >
                          Comprovante
                        </button> */}
                      </div>
                    </div>
                  </div>
                </section>
              )}
              {!fatura?.dataConciliacao && (
                <div className="row pr-5 pl-5 pb-4">
                  <div className="col-12 ">
                    <h1 className="display-15 mb-0">Dados do Cartão</h1>
                    <p className="mt-0">
                      <small>Dados do seu cartão de crédito</small>
                    </p>
                  </div>
                  <div className="col-12 mt-1">
                    <label htmlFor="inp" className="inp">
                      <InputMask
                        mask="9999 9999 9999 9999"
                        disableUnderline
                        value={form?.cardNumber}
                        onChange={event => {
                          onPress("cardNumber", event.target.value);
                        }}
                        type="text"
                        id="inp"
                        placeholder="&nbsp;"
                      />
                      <span className="label">Número do cartão</span>
                      <span className="focus-bg"></span>
                    </label>
                  </div>
                  <div className="col-12 mt-2">
                    <label htmlFor="inp" className="inp">
                      <input
                        value={form?.cardName}
                        onChange={event => {
                          onPress("cardName", event.target.value);
                        }}
                        type="text"
                        id="inp"
                        placeholder="&nbsp;"
                      />
                      <span className="label">Nome impresso no cartão</span>
                      <span className="focus-bg"></span>
                    </label>
                  </div>
                  <div className="col-12 col-sm-4 mt-2">
                    <label htmlFor="inp" className="inp">
                      <InputMask
                        mask="99"
                        value={form?.monthCard}
                        onChange={event => {
                          onPress("monthCard", event.target.value);
                        }}
                        type="text"
                        id="inp"
                        placeholder="&nbsp;"
                      />
                      <span className="label">Mês Ex: 99</span>
                      <span className="focus-bg"></span>
                    </label>
                  </div>
                  <div className="col-12 col-sm-4 mt-2">
                    <label htmlFor="inp" className="inp">
                      <InputMask
                        mask="9999"
                        value={form?.yearCard}
                        onChange={event => {
                          onPress("yearCard", event.target.value);
                        }}
                        type="text"
                        id="inp"
                        placeholder="&nbsp;"
                      />
                      <span className="label">Ano Ex: 9999</span>
                      <span className="focus-bg"></span>
                    </label>
                  </div>
                  <div className="col-12 col-sm-4 mt-2">
                    <label htmlFor="inp" className="inp">
                      <InputMask
                        value={form?.cvv}
                        onChange={event => {
                          onPress("cvv", event.target.value);
                        }}
                        type="text"
                        id="inp"
                        placeholder="&nbsp;"
                      />
                      <span className="label">Código de segurança</span>
                      <span className="focus-bg"></span>
                    </label>
                  </div>
                  <div className="col-12 mt-4 mb-2">
                    <div
                      className="col-md-6 col-sm-12"
                      style={{ margin: "0 auto" }}
                    >
                      <button
                        disabled={loading}
                        onClick={() => efetuarPagamento()}
                        type="button"
                        className="text-button btn btn-outline-primary btn-lg btn-block"
                      >
                        <FiCheck size={20} className="mr-2 mb-1" /> Efetuar
                        Pagamento
                      </button>
                    </div>
                  </div>
                  <hr />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="wrap-contact100 last-section">
            <div className="dis-flex text-center row p-t-10 p-b-20">
              <div className="col-md-4">
                <span>
                  <FiAward /> SITE SEGURO
                </span>
              </div>
              <div className="col-md-4">
                <span>
                  <FiLock /> AMBIENTE SEGURO
                </span>
              </div>
              <div className="col-md-4">
                <span>
                  <FiShield />
                  SSL CERTIFICADO
                </span>
              </div>
            </div>

            <div className="row text-center p-t-45 p-b-20">
              <div className="col-md-6 divisor-logo">
                <span style={{ fontWeight: "bold" }}>GRUPO</span>
                <div>
                  <div className="row p-b-20 p-t-10">
                    <div className="col-md-12 text-center">
                      <img
                        className="m-r-15"
                        width={140}
                        src={process.env.PUBLIC_URL + "/img/logo-grupo-sou.svg"}
                        alt="img-logo"
                      />
                      <img
                        className="m-l-15"
                        width={140}
                        src={process.env.PUBLIC_URL + "/img/logo-br5.svg"}
                        alt="img-logo"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <img
                        className="m-r-15"
                        width={140}
                        src={
                          process.env.PUBLIC_URL + "/img/logo-top-seguros.svg"
                        }
                        alt="img-logo"
                      />
                      <img
                        className="m-l-15"
                        width={140}
                        src={
                          process.env.PUBLIC_URL +
                          "/img/logo-sou-mais-saude.svg"
                        }
                        alt="img-logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 divisor-logo">
                <span style={{ fontWeight: "bold" }}>
                  PRINCIPAIS PARCEIROS NACIONAIS
                </span>
                <div>
                  <div className="row p-b-20 p-t-10">
                    <div className="col-md-12 text-center">
                      <img
                        className="m-r-15"
                        width={140}
                        src={process.env.PUBLIC_URL + "/img/logo-drogasil.svg"}
                        alt="img-logo"
                      />
                      <img
                        className="m-l-15"
                        width={140}
                        src={
                          process.env.PUBLIC_URL + "/img/logo-sulamerica.svg"
                        }
                        alt="img-logo"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <img
                        className="m-r-15"
                        width={140}
                        src={process.env.PUBLIC_URL + "/img/logo-seguro.svg"}
                        alt="img-logo"
                      />
                      <img
                        className="m-l-15"
                        width={140}
                        src={
                          process.env.PUBLIC_URL + "/img/logo-droga-raia.svg"
                        }
                        alt="img-logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              title="Pague agora com PIX"
              visible={modalPix}
              onOk={() => setModalPix(false)}
              onCancel={() => setModalPix(false)}
              width={1000}
            >
              <p style={{ textAlign: "center", fontSize: "16px" }}>
                <h3 className="text-primary text-bold">Código PIX copiado!</h3>
                Para realizar seu pagamento via PIX, vá no seu aplicativo
                bancário e utilize o código copiado para pagar.
                {fatura && fatura.pix && (
                  <div>
                    <hr />
                    <h6>QrCode PIX</h6>
                    <img
                      width="200"
                      src={`data:image/png;base64, ${fatura.pix.encodedImage}`}
                    />
                    <hr />
                    <h6>Código PIX (Copia e cola)</h6>
                    {fatura.pix.payload}
                    <br />
                  </div>
                )}
                <label className="label-info">
                  ATENÇÃO! <br />A COBERTURA DO SEGURO E FUNERAL SOMENTE SERÃO
                  REATIVADOS E TERÃO COBERTURA 60 (SESSENTA DIAS) APÓS QUITAÇÃO
                  DAS MENSALIDADES ATRASADAS
                </label>
              </p>
            </Modal>
            <hr />
            <div style={{ textAlign: "center" }}>
              <span
                className="contact100-form-sub-title text-last-section p-b-5 text-center"
                style={{ fontSize: `${17}px` }}
              >
                Aviso Legal
              </span>
              <br />
              <span
                className="contact100-form-sub-title text-last-section p-b-5"
                style={{ fontSize: `${12}px` }}
              >
                BR5 Benefícios trata-se única e exclusivamente de administração
                de um Cartão de Descontos, ou seja, NÃO SE TRATA DE UM PLANO DE
                SAÚDE, seguro saúde ou assistência de saúde, não havendo
                garantia e nem responsabilização pelos serviços oferecidos e
                pelo pagamento de despesas, nem assegurando desconto em todos os
                serviços obrigatoriamente garantidos pelo plano de saúde. Tudo
                que o cliente usar ou comprar será por ele pago ao parceiro,
                assegurando-se apenas os preços e descontos que constam na
                relação de empresas e serviços conveniados disponibilizados no
                site. www.br5beneficios.com.br. O atendimento será agendado em
                até 24 horas, mediante disponibilidade na clínica. Consulte a
                clínica parceira para as especialidades disponíveis e condições
                para marcar as consultas em nosso aplicativo
              </span>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </>
  );
}

PagamentoBoleto.propTypes = {
  match: PropTypes.object.isRequired
};

export default PagamentoBoleto;
