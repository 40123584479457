import React, { useState } from "react";
import PropTypes from "prop-types";
import api from "../../api";
import { message } from "antd";

// import { useHistory } from "react-router-dom";
// import Jazigo from "../Upsell";

const Telemedicina = ({ match }) => {
  // const history = useHistory();
  const { params } = match;

  // const [contas, setContas] = useState([]);
  // const [cliente, setCliente] = useState({});
  const [loading, setLoading] = useState(false);
  // const [upsell, setUpsell] = useState(false);

  // useEffect(() => {
  //   populate();
  // }, []);

  // const populate = async () => {
  //   try {
  //     setLoading(true);
  //     const { cliente, contas } = await api.get(`services/carne/${params?.id}`);

  //     setContas([...contas]);
  //     setCliente({ ...cliente });
  //   } catch (error) {
  //     console.log(JSON.stringify(error));
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleSubmit = () => {
  //   setUpsell(true);
  // };

  const handleSend = async ({ upsell }) => {
    const key = params?.id;
    const data = {
      planId: key,
      items: ["telemedicina"]
    };

    if (upsell) data.items.push("jazigo");

    try {
      message.loading({
        content: "Um momento, estamos fazendo sua assinatura:)",
        key
      });
      setLoading(true);
      await api.post(`services/adicionais`, data);
      // message.success({
      //   content: "Assinatura concluida :)",
      //   key,
      //   duration: 10
      // });

      window.location.href = `/telemedicina/obrigado`;

      // setUpsell(false);
    } catch (error) {
      message.error({
        content: "Opss... Não foi possível fazer sua assinatura!",
        key,
        duration: 10
      });
    } finally {
      setLoading(false);
    }
  };

  // if (upsell) return <Jazigo handleSend={handleSend} loading={loading} />;

  return (
    <>
      <div className="home home-3 home-p " style={{ position: "inherit" }}>
        <div className="container-xs">
          <div className="row">
            <div className="col-md-12 col-md-offset-1 offer">
              <div
                className="hero-content wow fadeIn"
                style={{ display: "block" }}
              >
                <div className="hero-content-inner mb-2 text-center d-flex flex-column justify-content-center ">
                  <p>
                    <iframe
                      style={{ width: "auto" }}
                      height="315"
                      src="https://www.youtube.com/embed/3xFKO57KndY?autoplay=1"
                      title="YouTube video player"
                      allow="autoplay;"
                      allowfullscreen
                    ></iframe>
                    {/* <iframe
                      width="500vw"
                      height="350vh"
                      src=""
                      title="YouTube video player"
                      allowfullscreen
                    /> */}
                  </p>

                  <span className="text-medium mb-3 mt-2">Tenha</span>
                  <div className="w-75 mx-auto">
                    <div className="bg-white">
                      <span className="text-offer-primary text-bold taxed-title">
                        TELEMEDICINA
                      </span>
                    </div>
                  </div>

                  <span className="mt-3 mb-3 text-medium">
                    DE <s className="font-weight-bold text-price ">R$ 29,90</s>
                  </span>

                  <span className="text-medium mt-2">POR APENAS</span>
                  <span className="text-price">R$ 9,90</span>
                  <span className="text-medium mb-4">
                    PARA TITULAR, ESPOSA E FILHOS POR MÊS A MAIS NA SUA PARCELA
                  </span>

                  <h5 className=" text-white text-bold mt-3">
                    Como funciona a TELEMEDICINA?
                  </h5>

                  <span className=" ">
                    Com a TELEMEDICINA, você terá consultas com médicos
                    capacitados e treinados para atender assim como uma consulta
                    presencial.
                    <br />
                    <br />
                    ATENDIMENTOS ILIMITADOS EM TODO O BRASIL! 24 HORAS POR DIA.
                    7 DIAS POR SEMANA!
                  </span>

                  <div className="mx-auto mt-2 w-100">
                    <button
                      disabled={loading}
                      type="button"
                      className="btn btn-primary button-carne w-100 text-price"
                      onClick={() => handleSend(false)}
                    >
                      ASSINAR AGORA!
                    </button>
                  </div>
                  <div className="mx-auto mt-5 mb-2 w-50">
                    <span className="text-bold mb-1 d-block">
                      Ainda está com alguma dúvida?
                    </span>
                    <a
                      target="_blank"
                      href="https://encurtador.com.br/gqtBW"
                      disabled={loading}
                      type="button"
                      className="btn btn-primary button-duvidas w-100"
                      rel="noreferrer"
                      // onClick={() => handleSend(false)}
                    >
                      WHATSAPP BR5 BENEFÍCIOS
                    </a>
                  </div>
                  <span>
                    * Caso você tenha agregado, será cobrado R$ 9,90 a mais por
                    cada um.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Telemedicina.propTypes = {
  match: PropTypes.object.isRequired
};

export default Telemedicina;
