import React from "react";

const Telemedicina = () => {
  return (
    <>
      <div className="home home-3 home-p " style={{ position: "inherit" }}>
        <div className="container-xs">
          <div className="row">
            <div className="col-md-12 col-md-offset-1 offer">
              <div
                className="hero-content wow fadeIn"
                style={{ display: "block" }}
              >
                <div className="hero-content-inner mb-2 text-center d-flex flex-column justify-content-center ">
                  <div className="w-100 mx-auto mb-3">
                    <div className="bg-white">
                      <span
                        className="text-offer-primary text-bold taxed-title"
                        style={{ fontSize: "50px" }}
                      >
                        Muito Obrigado!
                      </span>
                    </div>
                  </div>

                  <span className="text-medium mt-2">
                    Sua assinatura foi concluída com sucesso! Seu carnê já foi
                    atualizado. você pode entrar em contato através do nosso
                    WHATSAPP para saber mais informações de como usar a
                    telemedicina
                  </span>

                  <h5 className=" text-white text-bold mt-3">
                    Como funciona a TELEMEDICINA?
                  </h5>

                  <span className=" ">
                    Com a TELEMEDICINA, você terá consultas com médicos
                    capacitados e treinados para atender assim como uma consulta
                    presencial.
                    <br />
                    <br />
                    ATENDIMENTOS ILIMITADOS EM TODO O BRASIL! 24 HORAS POR DIA.
                    7 DIAS POR SEMANA!
                  </span>

                  <div className="mx-auto mt-5 mb-2 w-50">
                    <span className="text-bold mb-1 d-block">
                      Ainda está com alguma dúvida?
                    </span>
                    <a
                      target="_blank"
                      href="https://encurtador.com.br/gqtBW"
                      type="button"
                      className="btn btn-primary button-duvidas w-100"
                      rel="noreferrer"
                      // onClick={() => handleSend(false)}
                    >
                      WHATSAPP BR5 BENEFÍCIOS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Telemedicina;
